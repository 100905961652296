<template>
  <div class="left_sidebar_inner">
    <ul class="left_sidebar" v-for="(menu, index) in sidebars" :key="index">
      <li
        :class="
          currentUrl[3] == menu.link ? `active` : ``
        "
      >
        <router-link :to="'/' + menu.link">
          <img :src="getImgUrl(menu.image)" />{{ menu.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DashboardSidebar",
  props: {
    sidebars: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentUrl: window.location.href.split("/"),
    };
  },
  methods: {
    getImgUrl(pet) {
      var images = require.context("../assets/images/User/", false, /\.png$/);
      return images("./" + pet);
    },
  },
};
</script>

<style scoped>
.left_sidebar li {
  padding-left: 30px;
}
.left_sidebar li.active {
  border: 3px solid #d5ede9;
  border-radius: 10px;
  background-color: #42aea3;
  color: #fff;
}

.left_sidebar li.active a {
    pointer-events: none;
}

#dm ul.left_sidebar li {
  padding: 15px 15px;
}
</style>
