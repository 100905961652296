<template>
    <main id="dm">
        <Header :title="main_name" />
        <div class="main_container">
            <div class="rc_main_container margin_top">
                <section class="dashboard_section">
                    <BreadCrumb :main_name="main_name" :links="links" />
                    <!-- <div class="dashboard_tab_section">
                    <DashboardTab :tabs="tabs" />
                </div> -->
                    <div class="tab_content_section">
                        <div class="sidebar">
                            <DashboardSidebar :sidebars="sidebars" />
                        </div>
                        <slot />
                    </div>
                </section>
            </div>
        </div>
        <Footer />
    </main>
</template>
<script>
import Footer from '../../components/Footer.vue';
import Header from '../../components/Header.vue';
import BreadCrumb from '../../components/BreadCrumb.vue';
// import DashboardTab from '../../components/DashboardTab.vue';
import DashboardSidebar from '../../components/DashboardSidebar.vue';

export default {
    props: {
        main_name: String,
        links: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            'text': 'Loading...',
            tabs: [
                { 'name': 'Dashboard', 'image': 'home_2.png', 'tab_link': 'Mechanics' },
                { 'name': 'Restaurant', 'image': 'restaurant 1.png', 'tab_link': 'Restaurant' },
                { 'name': 'Club House', 'image': 'basketball 1.png', 'tab_link': 'ClubHouse' },
                { 'name': 'Movie', 'image': 'video-camera 1.png', 'tab_link': 'Movie' },
                { 'name': 'Spa', 'image': 'spa1.png', 'tab_link': 'Spa' },
                { 'name': 'Rio Store', 'image': 'shopping-cart-add1.png', 'tab_link': 'RioStore' },
                { 'name': 'Cleaning', 'image': 'clean1.png', 'tab_link': 'Cleaning' },
            ],
            sidebars: [
                { 'name': 'Dashboard', 'image': 'dashboard.png', 'link': 'dashboard' },
                { 'name': 'My Profile', 'image': 'user_1.png', 'link': 'my-profile' },
                { 'name': 'My Booking', 'image': 'Calendar_check.png', 'link': 'my-booking' },
                { 'name': 'Event', 'image': 'Star_circle.png', 'link': 'events-rio' },
                { 'name': 'Villas', 'image': 'calender.png', 'link': 'platinum-villa' },
                { 'name': 'Booking History', 'image': 'file-text.png', 'link': 'booking-history' },
                { 'name': 'Booking Pending', 'image': 'file-text.png', 'link': 'booking-pending' },
                { 'name': 'Support Us', 'image': 'Phone_call.png', 'link': 'contact-us' },
            ],
        };
    },
    components: {
        Header,
        Footer,
        BreadCrumb,
        // DashboardTab,
        DashboardSidebar,
    },
}
</script>

<style scoped>
@import '../../assets/styles/User/inner_page_css.css';
@import '../../assets/styles/User/inner_page_up.css';
@import '../../assets/styles/User/inner_page.css';
@import '../../assets/styles/User/font.css';
@import '../../assets/styles/User/style.css';
</style>