<template>
    <div class="bredcumb_section">
        <div class="bredcumb_section_row">
            <div class="bredcumb_section_row_col">
                <template v-for="bc,index in links" :key="index">
                    <router-link :to="{ name: bc.link }">{{ bc.name }} > </router-link>
                </template>
                <span>{{ main_name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BreadCrumb',
    props: {
        main_name: String,
        links: {
            type: Array,
            required: true
        },
    },
}
</script>
<style scoped>
.bredcumb_section_row_col a {
    color: #959595 !important;
}
</style>